import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const storage =
  "https://firebasestorage.googleapis.com/v0/b/aristocrat-inn.appspot.com/o";

const storage_2 =
  "https://firebasestorage.googleapis.com/v0/b/lite-weight-materials.appspot.com/o";

const imgur = "https://i.imgur.com/";

const cloudinaryUrl = "https://api.cloudinary.com/v1_1/teamtigers/auto/upload";
const emailSenderApi = "https://aristocrat-api.herokuapp.com/api/data";
const successResponse = "Submitted successfully. We will get back to you soon.";
const errorResponse = "Something went wrong";
const successColor = "blue darken-3";
const errorColor = "red";

export default new Vuex.Store({
  state: {
    navbarMenu: [
      { route: "/", routeName: "Home" },
      { route: "rooms", routeName: "Rooms" },
      { route: "restaurant", routeName: "Restaurant" },
      { route: "conference", routeName: "Conference" },
      { route: "booking", routeName: "Booking" },
      { route: "contact", routeName: "Contact" },
      { route: "career", routeName: "Career" }
    ],
    vectors: [
      {
        id: "spark",
        source: `${storage_2}/aristocrat%2Fspark.svg?alt=media&token=6cbfd126-42e8-4c53-8438-00fd7f64b8f2`
      },
      {
        id: "block",
        source: `${storage_2}/aristocrat%2Fblock.svg?alt=media&token=91f84201-1d3d-465a-a5cc-2c628c101ac3`
      },
      {
        id: "ellipse",
        source: `${storage_2}/aristocrat%2Fellipse.svg?alt=media&token=b837f716-2b79-4937-93f6-48d826ecf29b`
      }
    ],
    constant: {
      miniEllipse: `${storage_2}/aristocrat%2FEllipse%20292.svg?alt=media&token=44241df7-8a52-4483-bdea-51cdfd0ec8fd`,
      bigEllipse: `${storage_2}/aristocrat%2FGroup%2018.svg?alt=media&token=cb063b18-7f0d-44a8-ab2f-c54a3d0f33c6`,
      cleanEllipse: `${storage_2}/aristocrat%2FEllipse%20293.svg?alt=media&token=993689ae-8a09-405a-aa6e-b5eb04f04c1a`,
      offerImage: `${storage_2}/aristocrat%2FGroup%2015.svg?alt=media&token=71d32b24-5d65-4ae4-8892-ad3f48fbb462`,
      checkCircle: `${storage_2}/aristocrat%2Fcheck-circle.svg?alt=media&token=41381b37-c493-4718-a783-d4e4048fe242`,
      blockImage: `${storage_2}/aristocrat%2Fblock.svg?alt=media&token=91f84201-1d3d-465a-a5cc-2c628c101ac3`,
      offers: [
        "Complementary buffet breakfast.",
        "Free WIFI in every room speed up to 50 mbps (unlimited).",
        "Free newspaper, welcome drinks & fruit basket on arrival.",
        "Complementary Airport Pickup.",
        "International Airport booth available to assist guests at the Airport 24 hrs.",
        "VISA on Arrival (On Request).",
        "<span>Airport protocol service (Immigration, Customes, On arrival Visa, Luggage clearance etc.)</span>",
        "24 hrs. Business Center/ secretarial services.",
        "24 Hrs. Car Service.",
        "Concierge service.",
        "ISD Telephone from Room.",
        "Business Center and well-equipped Conference Hall.",
        "Laundry Service.",
        "Safety Locker facilities.",
        "24 hrs. Doctor on call,",
        "Sight Seeing Tours.",
        "Air Ticketing.",
        "Health Club."
      ]
    },
    heroImages: {
      home: `${storage_2}/aristocrat%2Fcommon%2Fheroes%2Fhero-1.png?alt=media&token=c43838ad-d8fb-44d2-bdba-42633b46e125`,
      rooms: `${storage_2}/aristocrat%2Fcommon%2Fheroes%2Fhero-1.png?alt=media&token=c43838ad-d8fb-44d2-bdba-42633b46e125`,
      restaurant: `${storage_2}/aristocrat%2Fcommon%2Fheroes%2Fhero-restaurant.png?alt=media&token=f4e4875d-ecc0-4bc8-859c-efa78da99503`,
      conference: `${storage_2}/aristocrat%2Fcommon%2Fheroes%2Fhero-conf.png?alt=media&token=2df8bdcb-57f0-416d-830d-b1cbc02018f9`,
      booking: `${storage_2}/aristocrat%2Fcommon%2Fheroes%2Fhero-2.png?alt=media&token=d8a7d86d-6782-4f37-a686-048346d505cb`,
      contact: `${storage_2}/aristocrat%2Fcommon%2Fheroes%2Fhero-contact.png?alt=media&token=e3bc589d-2ebc-4e92-92d5-b212be4687cf`,
      career: `${storage_2}/aristocrat%2Fcommon%2Fheroes%2Fhero-career.png?alt=media&token=09424eea-3c12-4667-be5d-a7b2bdf81e6f`
    },
    homeHeroSectionTitles: [
      {
        text: "Welcome to",
        style: "primary-font header-title-small font-weight-medium"
      },
      {
        text: "Hotel Aristocrat Inn",
        style: "tertiary-font header-title-big primary--text"
      },
      {
        text:
          "An excellent hotel located in the heart of the diplomatic zone in Dhaka, Gulshan 2, Bangladesh. The area is highly secured surrounded by Embassies and international Clubs.",
        style: "quaternary-font font-weight-light",
        margin: "mt-5"
      },
      {
        text: `For Booking: <a style="text-decoration: none;" class="black--text" href="mailto:a_inn@hotmail.com">a_inn@hotmail.com</a>`,
        style: "quaternary-font font-weight-medium",
        margin: "mt-4"
      },
      {
        text: `Contact: <a href="tel:+8801817019200" style="text-decoration: none;" class="black--text">+880 1817019200</a>, <a href="tel:+8802222281014" style="text-decoration: none;" class="black--text">+8802 222281014</a>`,
        style: "quaternary-font font-weight-medium",
        margin: "mt-1"
      }
    ],
    homeBrandingCards: [
      {
        title: "Free wifi",
        image: `${storage_2}/aristocrat%2Fwifi.svg?alt=media&token=47ddded6-bf9c-46a3-8f3f-fa439af77308`
      },
      {
        title: "Airport Pickup",
        image: `${storage_2}/aristocrat%2Fcar.svg?alt=media&token=0173f8c7-6b1a-4102-82f8-38995de9b6ea`
      },
      {
        title: "Buffet Breakfast",
        image: `${storage_2}/aristocrat%2Fbuffet.svg?alt=media&token=293b6af1-2626-461c-812d-bf81d060035a`
      },
      {
        title: "Fruit Basket",
        image: `${storage_2}/aristocrat%2Fbasket.svg?alt=media&token=100d6366-09a4-4926-bfa1-bfaa7023b580`
      }
    ],
    // We serve a wide variety of food. You will get foods like Continental, Chinese, Thai, Italian, Indian & Bangladeshi cuisine at our restaurant.
    // Our Conference hall is well equipped with Projector, Multimedia, Sound system and other logistic supports. We setup round table, board room, U shape, Class room and theater style. We also do Family get-together, all kind of parties or any other occasions and events.
    services: [
      {
        showHeader: true,
        title: "Atmosphere Restaurant",
        subTitle:
          "Our 'Open Air' rooftop restaurant provides a refreshing atmosphere and view for our guests with our multi-experienced chefs serving Buffet Breakfast, Continental, Live BBQ, Chinese, Thai, Italian, Indian and Bangladeshi cuisines.",
        showMiniEllipse: true,
        image: `${storage_2}/aristocrat%2Fcommon%2Frest-mango.jpg?alt=media&token=a1000616-2a25-46a8-99ff-9f5777f64c19`,
        routeLink: "/restaurant"
      },
      {
        isExchnaged: true,
        title: "Royal Banquet & Conference",
        subTitle:
          "This is our events venue for Weddings, birthdays, private functions, and corporate events. We can help you to create a memorable experience for you and your guests or clients. We also provide well-equipped digital and audio support for your event to get you going. Capacity (150).",
        showBigEllipseWithSpark: true,
        image: `${storage_2}/aristocrat%2Fcommon%2Fconf-mango.jpg?alt=media&token=288c391c-6d7c-433c-a9d9-4e58b8bfe957`,
        routeLink: "/conference"
      },
      {
        title: "Lobby & Balcony",
        subTitle:
          "This is one of the special attraction of our hotel. Our lobby is very spacious and followed by a balcony in each floor. Where you can have your small meeting and relax during your leisure time",
        showBigEllipse: true,
        image: `${storage_2}/aristocrat%2Fcommon%2Fbelcony.jpg?alt=media&token=0c28bcb5-5f3d-4ca9-a7a3-162aa463d7db`
      }
    ],
    footerTexts: {
      title: "Hotel Aristrocart Inn",
      address: "House # 12, Road #68, 68A, Gulshan-2, Dhaka-1212, Bangladesh",
      communication: `Tel: 
                      <a href="tel:+8802222281014" style="text-decoration:none;" class="white--text">+8802222281014</a>,
                      <a href="tel:+8802222292327" style="text-decoration:none;" class="white--text">+8802222292327</a>
                      
                      <br /><br />
                      Cell:
                      <a href="tel:+8801817019200" style="text-decoration:none;" class="white--text">+880-1817019200</a>,
                      <a href="tel:+8801732279155" style="text-decoration:none;" class="white--text">+880-1732279155</a>,
                      <a href="tel:+8801924304304" style="text-decoration:none;" class="white--text">+880-1924304304</a>
                      <br /><br />
                      Email:
                      <a class="tertiary--text" style="text-decoration:none;" href="mailto:a_inn@hotmail.com">a_inn@hotmail.com</a>`,
      copyright: `©${new Date().getFullYear()} — Hotel Aristocrat Inn Ltd. All rights
      reserved`,
      facebook: `https://www.facebook.com/AristocratInn`
    },
    appHeroTexts: {
      rooms: {
        taglines: ["Choose Your Room", "Enjoy Your Stay"],
        subTitle:
          "Enjoy your stay in style with 5 different types of rooms to choose from Deluxe to Aristocrat Suite – with bird's eye view of the city greenery from few rooms. Each room is elegantly designed with luxury and comfort in mind."
      },
      // ["Try Our Atmosphere", "Restaurant"]
      restaurant: {
        taglines: [
          "Our 'Open Air' roof top restaurant provides a refreshing atmosphere and view for our guests with our multi experienced chefs serving Buffet Breakfast, Continental, Live BBQ, Chinese, Thai, Italian, Indian and Bangladeshi cuisines."
        ],
        subTitle: ""
      },
      conference: {
        taglines: [
          "This is our events venue for Weddings, birthdays, private functions, and corporate events. We can help you to create a memorable experience for you and your guests or clients. We also provide well-equipped digital and audio support for your event to get you going. Capacity (150). "
        ],
        subTitle: ""
      },
      booking: {
        taglines: ["Book Your Desired", "Room in a Few Simple Steps"],
        subTitle:
          "We value your time and security. Please fill out the following form with care."
      },
      contact: {
        taglines: ["Contact with us. We are", "happy to help anytime"],
        subTitle:
          "We would be delighted to hear from you. Please send us a message and we will get back to you as soon as possible."
      },
      career: {
        taglines: ["Build your career", "at Aristocrat Inn"],
        subTitle:
          "We would be delighted to hear from you. Please send us a message and we will get back to you as soon as possible."
      }
    },
    rooms: [
      {
        photos: [
          `${storage}/lite%2Fdelux%2F1.png?alt=media&token=a32c294d-f691-4cea-96f7-b7de9607cd5f`,
          `${storage}/lite%2Fdelux%2F2.png?alt=media&token=173a6864-847f-4725-b1de-4a902f580167`,
          `${storage}/lite%2Fdelux%2F3.png?alt=media&token=b8a498ff-c3f7-42de-a104-c5a242390e9a`,
          `${storage}/lite%2Fdelux%2F4.png?alt=media&token=75f38382-2beb-4ab6-950b-fc14f6a998dc`,
          `${storage}/lite%2Fdelux%2F5.png?alt=media&token=f5566e91-233f-4e60-a46c-aaa18ae4823b`
        ],
        title: "Deluxe",
        subtitle:
          "All Deluxe rooms have a large size bed. Other room amenities include Split Air Conditions, 32'' LCD TV, International channels, Mini Bar, Complimentary Wi-Fi connections, Intercom with Local/International dial and work desk. Complementary Breakfast (Continental/Indian), Complementary Flower, Fruit Basket, Welcome drinks on arrival, Free Newspaper"
      },
      {
        photos: [
          `${storage}/lite%2Fsup-delux%2F1.png?alt=media&token=997a2d5d-b059-4c10-9006-775b1e659552`,
          `${storage}/lite%2Fsup-delux%2F2.png?alt=media&token=714badbe-a6de-4cc1-aab6-ac826f2eb280`,
          `${storage}/lite%2Fsup-delux%2F3.png?alt=media&token=c2bb903d-7d4e-4a5f-a882-efaf70aa666a`,
          `${storage}/lite%2Fsup-delux%2F4.png?alt=media&token=f817b4ee-3c65-4b1a-89ce-0c681f8ced06`,
          `${storage}/lite%2Fsup-delux%2F5.png?alt=media&token=6a6ea97c-ab8b-46d5-860b-2c1fce9497cc`
        ],
        title: "Super Deluxe",
        subtitle:
          "All Super Deluxe rooms have a king size bed. Other room amenities include Split Air Conditions, 32'' LCD TV, International channels, Mini Bar,Complimentary Wi-Fi connections, Intercom with Local/International dial and work desk. Complementary Breakfast (Continental/Indian), Complementary Flower, Fruit Basket, Welcome drinks on arrival, Free Newspaper."
      },
      {
        photos: [
          `${storage}/lite%2Farist-delux%2F1.png?alt=media&token=7e3cb30e-1ceb-40ee-b3ba-b92fe33a2d99`,
          `${storage}/lite%2Farist-delux%2F2.png?alt=media&token=a5639368-3098-44ae-b004-a791e7bfd4c1`,
          `${storage}/lite%2Farist-delux%2F3.png?alt=media&token=f262d4e4-5830-4406-bfc6-d0374164fa8c`,
          `${storage}/lite%2Farist-delux%2F4.png?alt=media&token=83a74dc7-b85a-4013-8ef0-8672cca8d538`,
          `${storage}/lite%2Farist-delux%2F5.png?alt=media&token=19dc44cd-9e34-4fdb-8d44-0122b11db65f`,
          `${storage}/lite%2Farist-delux%2F6.png?alt=media&token=5dcf4717-568c-4639-b8d5-b5ed64812276`,
          `${storage}/lite%2Farist-delux%2F7.png?alt=media&token=f272f327-fea4-4daa-bdef-564a7dc4b250`,
          `${storage}/lite%2Farist-delux%2F8.png?alt=media&token=ea0562f1-e386-40d2-9ad0-076dd503eb76`
        ],
        title: "Aristocrat Deluxe",
        subtitle:
          "All Aristocrat Deluxe rooms have a large king size bed.Other room amenities include Split Air Conditions,32'' LCD TV, International channels, Mini Bar, Complimentary Wi-Fi connections,Intercom with Local/International dial an      d work desk. Complementary Breakfast (Continental/Indian) , Complementary Flower, Fruit Basket, Welcome drinks on arrival, Free Newspaper."
      },
      {
        photos: [
          `${storage}/lite%2Ftwin%2F1.png?alt=media&token=5b957fe3-96ec-433b-932d-54f76752c316`,
          `${storage}/lite%2Ftwin%2F2.png?alt=media&token=cafb6cc1-69c0-4456-acf4-0f455bf9de42`,
          `${storage}/lite%2Ftwin%2F3.png?alt=media&token=c68f5104-2863-4247-8922-a19db120e5e5`,
          `${storage}/lite%2Ftwin%2F4.png?alt=media&token=8f459c09-cc5d-4d16-a2a5-d6cd5c794b73`,
          `${storage}/lite%2Ftwin%2F5.png?alt=media&token=6d1f225f-f7d7-49af-b271-d77c9d21fa0b  `
        ],
        title: "Aristocrat Deluxe (Twin)",
        subtitle:
          "All Aristocrat Deluxe (Twin) rooms have a large double king size bed. Other room amenities include Split Air Conditions, 32'' LCD TV, International channels, Mini Bar, Complimentary Wi-Fi connections, Intercom with Local/International dial and work desk. Complementary Breakfast (Continental/Indian), Complementary Flower, Fruit Basket, Welcome drinks on arrival, Free Newspaper"
      },
      {
        photos: [
          `${storage}/lite%2Fsuite%2F1.png?alt=media&token=7de6f442-f40b-4241-8be9-36105719e470`,
          `${storage}/lite%2Fsuite%2F2.png?alt=media&token=6d6d7cf6-7889-401a-ae61-51c63cbcc058`,
          `${storage}/lite%2Fsuite%2F3.png?alt=media&token=c7464ed5-fc1e-4865-977a-1a37565287da`,
          `${storage}/lite%2Fsuite%2F4.png?alt=media&token=062a3429-f830-4c79-b592-b23f708b6390`,
          `${storage}/lite%2Fsuite%2F5.png?alt=media&token=f6b191cb-dd4d-4a9e-8a53-511edfae212f`,
          `${storage}/lite%2Fsuite%2F9.png?alt=media&token=3f4bebe5-78a0-4a0c-a412-eed51c42b8a8`
        ],
        title: "Aristocrat Suite",
        subtitle:
          "All Aristocrat Suite rooms have a large king size bed and the other room have a cozy sitting arrangements with 32” LED TV. Other room amenities include Split Air Conditions, 32'' LED TV, International channels, Mini Bar, Complimentary Wi-Fi connections, Intercom with Local/International dial and work desk. Complementary Breakfast (Continental/Indian), Complementary Flower, Fruit Basket, Welcome drinks on arrival, Free Newspaper."
      }
    ],
    restaurantPhotos: [
      `${imgur}/boYJq7h.jpg`,
      `${imgur}/pwCbQsh.jpg`,
      `${storage_2}/aristocrat%2Fcommon%2Frest-mango.jpg?alt=media&token=a1000616-2a25-46a8-99ff-9f5777f64c19`,
      `${imgur}/r9ZXott.jpg`,
      `${imgur}/fjZVoZw.jpg`,
      `${imgur}/VC2hR25.jpg`,
      `${imgur}/FbDkT2f.jpg`,
      `${imgur}/kiGc3BX.jpg`,
      `${imgur}/90DSIkV.jpg`,
      `${imgur}/YSczATe.jpg`,
      `${imgur}/OWzkVqP.jpg`,
      `${imgur}/LujL4Ou.jpg`,
      `${imgur}/USPLFgW.jpg`,
      `${imgur}/5HJRk97.jpg`,
      `${imgur}/jouBxeI.jpg`
    ],
    conferencePhotos: [
      `${imgur}/qO2prcX.jpg`,
      `${imgur}/BhJN8I4.jpg`,
      `${imgur}/8uS5DOA.jpg`,
      `${imgur}/mlWf7uQ.jpg`,
      `${imgur}/G2tiPUC.jpg`,
      `${imgur}/C1J68ow.jpg`,
      `${imgur}/9yJ9pqp.jpg`,
      `${imgur}/XsaGI8o.jpg`,
      `${imgur}/2eXkaeJ.jpg`
    ],
    snackbarText: "",
    snackbarColor: ""
  },
  getters: {
    getHeroImage: state => source => state.heroImages[source],
    getConstant: state => source => state.constant[source],
    getAppHeroTexts: state => source => state.appHeroTexts[source]
  },
  mutations: {
    SHOW_MESSAGE_IN_SNACKBAR: (state, payload) => {
      state.snackbarText = payload.text;
      state.snackbarColor = payload.color;
    }
  },
  actions: {
    async uploadFile(context, payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("upload_preset", "j7npnn8e");

      return await fetch(cloudinaryUrl, {
        method: "POST",
        body: formData
      })
        .then(response => response.json())
        .then(data => data)
        .catch(error => error);
    },
    async sendEmail(context, payload) {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payload,
        redirect: "follow"
      };

      return await fetch(emailSenderApi, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => error);
    },
    pingServer(context) {
      fetch(emailSenderApi, {
        method: "GET",
        redirect: "follow"
      })
        .then(response => response.json())
        .then(result => console.log(result.data))
        .catch(error => console.log("error", error));
    },
    showSuccessSnackBar({ commit }, payload) {
      commit("SHOW_MESSAGE_IN_SNACKBAR", {
        text: payload ? payload : successResponse,
        color: successColor
      });
    },
    showFailedSnackBar({ commit }, payload) {
      commit("SHOW_MESSAGE_IN_SNACKBAR", {
        text: payload ? payload : errorResponse,
        color: errorColor
      });
    }
  }
});
